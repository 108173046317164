import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["step", "email", "profileInvalid"];

  initialize() {
    this.hasInvalidField = false;
    const stepInputs = this.stepTarget.querySelectorAll("input");

    stepInputs.forEach((element) => {
      if (!element.className.includes("is-invalid")) return;

      element.addEventListener("input", () => {
        element.classList.remove("is-invalid");
      });
      // This allows us to have translated error messages instead of error messages that are
      // in the language of the browser language
      const customErrorMessage = element.getAttribute("data-error-message");

      this.insertError(
        element,
        customErrorMessage || element.validationMessage
      );
      this.hasInvalidField = true;
    });
  }

  // checks if the email is already in use
  async checkEmail() {
    const url =
      this.data.get("checkemail") +
      "?email=" +
      encodeURIComponent(this.emailTarget.value);

    const response = await fetch(url, {
      method: "POST",
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    return data;
  }

  async submit(event) {
    if (event !== undefined) {
      event.preventDefault();
    }

    this.hasInvalidField = false;

    const emailInput = this.emailTarget;
    const formElement = document.querySelector('[data-form="asd"]');
    const stepInputs = this.stepTarget.querySelectorAll("input");

    this.removeErrors();

    // checks if the email is already in use
    if (emailInput !== null) {
      await this.checkEmail().then(async (data) => {
        if (data.email_exists) {
          this.insertError(
            this.emailTarget,
            this.emailTarget.getAttribute("data-duplicate-error-message")
          );

          this.hasInvalidField = true;
        }
      });
    }

    if (stepInputs.length > 0) {
      stepInputs.forEach((element) => {
        if (element.checkValidity() == false) {
          element.addEventListener("input", () => {
            element.classList.remove("is-invalid");
          });
          // This allows us to have translated error messages instead of error messages that are
          // in the language of the browser language
          const customErrorMessage = element.getAttribute("data-error-message");

          this.insertError(
            element,
            customErrorMessage || element.validationMessage
          );
          this.hasInvalidField = true;
        }
      });
    }

    if (this.hasInvalidField) {
      this.profileInvalidTarget.classList.add("d-block");
    }

    // submit form if everything is successfull
    if (!this.hasInvalidField) {
      window.dispatchEvent(new CustomEvent("add-to-cart"));
      window.dispatchEvent(new CustomEvent("begin-checkout"));
      formElement.submit();
    }
  }

  removeErrors() {
    this.stepTarget.querySelectorAll(".is-invalid").forEach((element) => {
      element.classList.remove("is-invalid");
    });

    this.stepTarget.querySelectorAll(".invalid-feedback").forEach((element) => {
      element.remove();
    });
  }

  insertError(element, message) {
    if (element.nextElementSibling?.className === "invalid-feedback") {
      element.nextElementSibling?.remove();
    }
    element.classList.add("is-invalid");
    element.parentNode.insertAdjacentHTML(
      "beforeend",
      `<small class="invalid-feedback">${message}</small>`
    );
  }
}
