import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'accordion',
    'input',
  ];

  connect() {
    // Toggle the accordion based on the state of the input target on connect
    if (this.hasInputTarget) {
      const input = this.inputTarget;
      const event = {
        target: input
      }

      this.toggle(event);
    }
  }

  toggle(event) {
	if (!this.hasAccordionTarget) return;

    const accordion = this.accordionTarget;
    const accordionStyle = window.getComputedStyle(accordion);
    const accordionMaxHeight = accordionStyle.getPropertyValue('max-height');
    const shouldDisableInputs = event.target && !event.target.checked;

    if (accordionMaxHeight === 'none' && !event.target.checked) {
      // When we initially want to close an open accordion it does not have any `max-height` yet.
      // Meaning if we set it to `0px` it will NOT animate. Hence this ugly solution to set the `max-height`
      // and set it to `0px` 5ms later so we at least have an animation
      accordion.style.maxHeight = accordion.scrollHeight + 'px';

      setTimeout(() => {
        accordion.style.maxHeight = '0px';

        this.toggleInputsState(shouldDisableInputs);
      }, 5)
    } else if (accordionMaxHeight != '0px' && !event.target.checked) {
      accordion.style.maxHeight = '0px';

      this.toggleInputsState(shouldDisableInputs);
    } else {
      if (accordion.scrollHeight) {
        accordion.style.maxHeight = accordion.scrollHeight + 'px';
      }

      this.toggleInputsState(shouldDisableInputs);
    }
  }

  /*
  * Can be used to disable inputs and buttons in accordions that should NOT be submitted
  */
  toggleInputsState(state) {
	if (!this.hasAccordionTarget) return;

    const accordion = this.accordionTarget;
    const inputs = accordion.querySelectorAll('input');
    const buttons = accordion.querySelectorAll('button');
    const items = [...inputs, ...buttons];

    if (!items) return;

    items.forEach(item => {
      item.disabled = state;
    })
  }
}
