// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');

import "@hotwired/turbo-rails"
Turbo.session.drive = false

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '@stimulus/polyfills'; // Support IE11 and Safari 9
import "../components"

// https://github.com/miguelcobain/ember-paper/issues/1058#issuecomment-571134155
// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context('../controllers', true, /\.js$/);
Stimulus.load(definitionsFromContext(context))

// enable Stimulus debug mode in development
Stimulus.debug = process.env.NODE_ENV === 'development'
