import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'input',
    'node',
  ];

  connect() {
    if (this.hasInputTarget) {
      const input = this.inputTarget;

      // Reproduce the 'event' that's used in the `update` method
      const event = {
        target: input
      };

      // Update the node `innerHTML` on connect
      this.update(event);
    }
  }

  update(event) {
    const { target } = event;
    const { dataset } = target;

    if (!target) return;

    const { value } = target;

    if (value) {
      this.nodeTargets.forEach(node => {
        let numberValue = Number(value);
        const nodeDataset = node.dataset;

        // Allow the nodeTarget to store some additional value that should be added
        // to the value that they are receiving
        if (nodeDataset.additionalValue) {
          const additionalValue = Number(nodeDataset.additionalValue);

          // Add additional value if it actually is a number
          if (!isNaN(additionalValue)) {
            numberValue += additionalValue;
          }
        }

        const formattedValue = this.getValue(dataset.format, numberValue);

        node.innerHTML = formattedValue;
      })
    }
  }

  /*
  * Allows converting the binded data to a currency value
  */
  getValue(format, value) {
    switch(format) {
      case 'currency':
       return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(value);
      default:
        return value
    }
  }
}
