import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["profileInvalid"];

  initialize() {
    const invalidFields = this.element.getElementsByClassName("is-invalid");

    if (invalidFields.length > 0) {
      Array.from(invalidFields).forEach((field) => {
        const invalidFeedbackElement = field.nextElementSibling;
        field.addEventListener("input", () => {
          field.classList.remove("is-invalid");
        });

        if (field.getAttribute("data-error-message")) {
          invalidFeedbackElement.innerHTML =
            field.getAttribute("data-error-message");
        }
      });

      this.profileInvalidTarget.classList.add("d-block");
    }
  }
}
