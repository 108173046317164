import { Controller } from '@hotwired/stimulus';
import DirtyForm from 'dirty-form'

export default class extends Controller {
  static targets = [
    'answer',
    'submit',
    'note'
  ];

  initialize() {
  }

  connect() {
    this.submitTarget.style.cursor = 'not-allowed';
    this.noteTarget.style.display = 'block';

    new DirtyForm(this.element, {
      message: 'Je hebt wijzigingen aangebracht in het stemformulier maar je stem nog niet ingediend. Weet je zeker dat je weg wilt gaan?'
    });
  }

  check(e) {
    // https://stackoverflow.com/questions/49344260/validating-multiple-radio-button-groups-in-vanilla-js/49344385#49344385
    if ( Object.values( Array.prototype.reduce.call( this.answerTargets, (result, el) => Object.assign(result, { [el.name]: (result[el.name] || []).concat(el) }), {} ) ).some(q => !q.some(el => el.checked))) {
      e.preventDefault();
    } else {
      this.submitTarget.removeAttribute('disabled');
      this.submitTarget.style.cursor = 'pointer';
      this.noteTarget.style.display = 'none';
    }
  }
}
