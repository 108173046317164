import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use'

/*
 * This "beautiful" controller can be used to trigger and hide tooltips.
 * The Bootstrap tooltip library we're using does not hide on mobile
 * and is also very hard to trigger/show on mobile. Add this controller on some wrapper
 * that should act as a trigger for a tooltip inside that wrapper.
 */
export default class extends Controller {
  static targets = ["tooltip"]

  connect() {
    if ($(this.tooltipTarget).is(':visible')) {
      this.element.addEventListener('click', this.showTooltip);

      useClickOutside(this);
    }
  }

  disconnect() {
    this.element.removeEventListener('click', this.showTooltip);
  }

  clickOutside() {
    // removes all tooltips from the page when clicked outside
    // I couldn't get the `.tooltip('hide')` or other disabling methods to work
    $("div[role=tooltip]").remove();
  }

  showTooltip(event) {
    event.preventDefault();

    const tooltipElement = $(event.target).find('[data-tooltip-trigger-target="tooltip"]');

    $(tooltipElement).tooltip('show');
  }
}
